<template>
  <div>
    <header-portrate />
    <exports-print ref="exportbar" />
    <div>
      <bread-crumbs :items="items"></bread-crumbs>

      <tabs-comp :tabs="tabs" />
      <div>
        <iframe src="https://w.samcotec.com/" style="width:100%;height:calc(100vh - 200px)" />
      </div>

    </div>
    <Footer />
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
        <div style="padding:3px;">يرجى الانتظار ... Please Wait</div>
      </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/breadCrumbs.vue";
// import AddQuotation from '@/components/addQuotation.vue'
import TabsComp from "@/components/tabsComp.vue";
// import UpdateCustomer from '@/components/update-customer.vue'
import HeaderPortrate from "@/components/Header-Portrate.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "clientlist",
  components: {
    BreadCrumbs,
    TabsComp,
    HeaderPortrate,
    Footer,
  },
  data() {
    return {
      kMenu: JSON.parse(localStorage.getItem("_ECar_")),
      company_name: '',
      industrial: 0,
      mobile: "",
      customer_name: "",
      city: "",
      cpage: 0,
      openQuot: false,

      page: 1,
      pageCount: 0,
      search: "",
      tablerows: [],
      opselected: {},
      ifpselected: {},
      invorquot: 0,
      tab1: 1,
      tab2: 2,
      tab3: 3,
      ccustomer: {},
      dustrial: [],
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },

    items: function () {
      return {
        text: this.lang.customers,
        disabled: true,
        href: "/clientList",
      };
    },

    tabs: function () {
      const menu = this.$store.getters["state"].menu;
      const license = this.$store.getters["state"].licenseType.activemenu;
      const alicense = this.$store.getters["state"].licenseType;
      let currentMenu = menu[license];
      if (!license) {
        return false;
      }
      let cMenu = { main: {}, sub: [] };
      Object.values(currentMenu).forEach((value) => {
        const cM = localStorage.getItem("currentMenu");
        if (value.main.index == cM) {
          cMenu = value.sub;
        } else if (cM == null && value.main.index == 1) {
          cMenu = value.sub;
        }
      });

      let t = [];
      Object.values(cMenu).forEach((value) => {
        if (this.$route.path == value.href) {
          value.class = "mytab";
        } else {
          value.class = "mytab4";
        }
        if (this.lang.langname == "ar") {
          value.name = value.arname;
        } else {
          value.name = value.enname;
        }
        for (let i = 0; i < value.groupid.length; i++) {
          if (value.groupid[i] == this.$store.getters["state"].group_id) {
            if (alicense[value.perms[0]] || value.perms[0] == "any") t.push(value);
          }
        }
      });

      return t;
    },
  },
  methods: {
  },
  beforeCreate() {
    // this.$store.state.showFirst = true;
  },
  created() {
    
    // this.$store.state.showFirst = true;
  },
};
</script>
<style>
.myDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
</style>
